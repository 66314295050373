@import "mixins"
.bottomMenuContainer
  background: var(--menu_bg)
  box-shadow: var(--menu_shadow)
  height: var(--menuHeight)
  position: fixed
  z-index: 1000
  bottom: 0
  left: 0
  width: 100%
  @include flex

.menu
  width: 100%
  height: 100%
  @include flex
  max-width: var(--modalMaxWidth)
  margin: 0 auto

  > button
    background: transparent
    flex: 1
    flex-direction: column
    height: 100%
    text-align: center

    &:not(.profile) :global(.svg-icon)
      min-width: 18px
      min-height: 18px

  .login, .profile
    min-width: calc((100% - 90px) / 4 - 4px)
    margin: 0 4px
    padding: 0 4px
    color: rgba(var(--primaryColor))

  .login
    transition: all .15s
    :global(.svg-icon)
      background: var(--menu_loginUserIconBg)
      width: 28px
      height: 28px
      box-shadow: inset 0 0 0 1px var(--menu_loginUserIconShadow)
      border-radius: 100%
      margin-top: 3px
      margin-bottom: -6px
      justify-content: center

      svg
        width: 100%
        height: auto

        path
          fill: var(--menu_loginUserIconFill)

        > :not(path)
          opacity: 0.7
          filter: grayscale(100%)

  .profile
    > span
      margin-top: 3px
      margin-bottom: -6px
      position: relative
      @include flex

    :global(.svg-icon)
      background: var(--menu_profileUserIconBg)
      width: 28px
      height: 28px
      box-shadow: inset 0 0 0 1px var(--menu_profileUserIconShadow)
      border-radius: 100%
      justify-content: center
      transition: margin .16s

      svg
        width: 100%
        height: auto

    .verificationReminder
      position: absolute
      background: #fff
      width: 14px
      height: 14px
      @include flex
      justify-content: center
      border-radius: 100%
      left: 20px
      bottom: 1px

      > span
        @include flex
        justify-content: center

    .unreadMsgsCount
      position: absolute
      border-radius: 2px
      @include flex
      justify-content: center
      padding: 1px 2px
      line-height: 11px
      font-size: 11px
      min-width: 16px
      left: 28px
      transform: translateX(-50%)
      top: -2px
      background: var(--menu_msgCountBg)
      color: #000
      box-shadow: 0 0 4px rgba(0,0,0,.4)

    &.profileDefault.active
      > span
        :global(.svg-icon)
          margin-right: 8px
        &::after
          content: ""
          background-color: rgb(var(--thirdaryColor))
          mask-image: var(--icon-menu-double-arrow)
          width: 10px
          height: 10px
          transform: rotate(-90deg)

  .active
    color: #fff
    background: var(--menu_loginActiveBg)
    border-radius: 0 0 6px 6px
    margin-bottom: 7px
    height: calc(100% - 7px)
    box-shadow: 0 1px 0 0 var(--menu_loginActiveShadow)
    position: relative
    transition: all .2s

    &:not(.profileDefault)::after
      content: ""
      background-color: rgb(var(--thirdaryColor))
      mask-image: var(--icon-menu-double-arrow)
      width: 10px
      height: 10px
      transform: rotate(45deg)
      position: absolute
      left: 5px
      bottom: 5px

.betslipModal
  height: calc(100% - var(--menuHeight))
  top: 0
  z-index: 6
  font-size: 13px

  :global(.modal)
    width: 100%
    height: calc(100% - var(--headerHeight))
    overflow: auto
    background: var(--body_bg)
    display: flex
    flex-direction: column

  @media only screen and (max-height: 575.98px) and (orientation: landscape)
    z-index: 16
    :global(.modal)
      height: 100%

.loginModalCompact
  :global(.modal)
    height: auto
    width: 100%
    border-radius: 8px 8px 0 0
    background: var(--login_bg)
    max-height: 98%
    --field_bg: var(--login_fieldBg)

    > div
      padding: 0 8px 50px

      > :global(.imageNext)
        height: 24px
        margin: 12px auto 6px

  @media only screen and (max-height: 575.98px) and (orientation: landscape)
    z-index: 16
    --headerHeight: 0px
    :global(.modal) > div
      padding-bottom: 10px

.liveGameModal
  z-index: 5 // refactor @TODO check
  :global(.modal)
    width: 100%
    overflow: auto
    display: flex
    flex-direction: column
    background: var(--body_bg)
    border-top-left-radius: 8px
    border-top-right-radius: 8px
    border-top: 1px solid #000
    > i
      width: 40px
      height: 50px
      min-height: 50px
      top: 0
      position: sticky
      left: 100%
      border-radius: 0
      background: var(--live_gameSelectorCompactBg)
      z-index: 7
    .liveGameContainer
      min-height: var(--withoutHeaderHeight)
      margin-top: -50px

.accountModalCompact
  :global(.modal)
    height: auto
    width: 100%
    border-radius: 6px 6px 0 0
    max-height: 94%
    overflow: hidden
    display: flex
    flex-direction: column
  @media only screen and (max-height: 575.98px) and (orientation: landscape)
    z-index: 16
    --headerHeight: 0px
    :global(.modal)
      max-height: 100%

.userVerificationModal
  z-index: 1001
  :global(.modal)
    height: auto
    max-width: 400px
    border-radius: 6px
    max-height: 90%
    overflow: hidden
    box-shadow: 0 -1px rgba(var(--accountCompact_modalShadow), 0.4)

  @media only screen and (max-height: 575.98px) and (orientation: landscape)
    height: 100%
    top: 0
    :global(.modal)
      max-height: calc(100% - 20px)
