@import "mixins"
.floatingLogin
    position: fixed
    @include flex
    justify-content: center
    bottom: calc(var(--menuHeight) + 10px)
    width: calc(100% - 20px)
    border-top: 1px solid rgb(var(--thirdaryColor))
    border-radius: 8px
    padding: 10px 5px
    left: 10px
    overflow: hidden
    z-index: 4
    max-width: var(--modalMaxWidth)
    transform: translateX(-50%)
    left: 50%

    &::before
        content: ""
        width: 100%
        height: 100%
        background: var(--body_bg)
        opacity: .8
        position: absolute
        z-index: -1

    button
        flex: 1
        margin: 0 5px
